import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const PartnerCatalogService = {
    runOutdatedMapping: async (listIds) => {
        const result = await axios
            .post(url + `/run-mapping`, listIds, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
    ChangeStatus: async (data) => {
        const result = await axios
            .post(url + `/partner-catalogs/change-catalog-status`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
    StopMapping: async (catalogId, model) => {
        const result = await axios
            .get(url + `/partner-catalogs/stop-mapping/${catalogId}/${model}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return result;
    },
    runPartnerInventoryMapping: async (data) => {
        const res = await axios
            .post(
                url + `/partner-catalogs/run-partner-inventory-mapping`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            )
            .then((res) => {
                return {
                    data: res?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                return {
                    message: error?.response?.data?.msg,
                    status: error?.response?.data?.code,
                };
            });
        return res;
    },
};

export default PartnerCatalogService;
