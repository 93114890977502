import React from "react";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { USER_LOGOUT } from "../redux/types";
import { useCookies } from "react-cookie";
import Nav from "react-bootstrap/Nav";

function Header() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const isCatalogsManager =
        window.location.href.indexOf("/catalogs-manager") > -1 ? true : false;
    return (
        <header className="topbar" data-navbarbg="skin6">
            <nav className="navbar top-navbar navbar-expand-md">
                <div className="navbar-header" data-logobg="skin6">
                    <Link
                        className="nav-toggler waves-effect waves-light d-block d-md-none"
                        to=""
                    >
                        <i className="ti-menu ti-close"></i>
                    </Link>
                    <div className="navbar-brand">
                        <Link to="/home">
                            <span className="logo-text">
                                Cup<span className="purple-text">id</span>&nbsp;
                            </span>
                        </Link>
                    </div>
                </div>
                <div
                    className="navbar-collapse collapse"
                    id="navbarSupportedContent"
                    style={{ background: "white" }}
                >
                    <ul className="navbar-nav float-left mr-auto ml-3 pl-1">
                        <li
                            className="nav-item dropdown"
                            style={{ display: "none" }}
                        >
                            <a
                                className="nav-link dropdown-toggle pl-md-3 position-relative"
                                href="javascript:void(0)"
                                id="bell"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="far fa-bell"></i>
                                <span className="badge badge-primary notify-no rounded-circle">
                                    0
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-left mailbox animated bounceInDown">
                                <ul className="list-style-none">
                                    <li>
                                        <a
                                            className="nav-link pt-3 text-center text-dark"
                                            href="javascript:void(0);"
                                        >
                                            <strong>Check all</strong>&nbsp;
                                            <i className="fa fa-angle-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        {isCatalogsManager && (
                            <>
                                {/* <div
                                    style={{
                                        display: "flex",
                                        alignItems: "start",
                                        gap: "16px",
                                    }}
                                >
                                    <NavItem
                                        to="/catalogs-manager"
                                        label="Overview"
                                        selected={
                                            location.pathname ===
                                            "/catalogs-manager"
                                        }
                                    />
                                    <NavItem
                                        to="/catalogs-manager/hotels-list"
                                        label="Properties"
                                        selected={
                                            location.pathname ===
                                            "/catalogs-manager/hotels-list"
                                        }
                                    />
                                </div> */}
                                {/* <div
                                    style={{
                                        width: "1174px",
                                        height: "80px",
                                        paddingLeft: "40px",
                                        paddingRight: "40px",
                                        background: "white",
                                        borderBottom: "2px #DADEF2 solid",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        display: "inline-flex",
                                    }}
                                >
                                    <div
                                        style={{
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            gap: 40,
                                            display: "flex",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: 109,
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                paddingTop: 12,
                                                paddingBottom: 12,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    color: "#505573",
                                                    fontSize: 18,
                                                    fontFamily: "Lato",
                                                    fontWeight: "400",
                                                    lineHeight: 24,
                                                    wordWrap: "break-word",
                                                }}
                                            >
                                                Overview
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: 116,
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                paddingTop: 12,
                                                paddingBottom: 12,
                                                borderBottom:
                                                    "2px #6275E7 solid",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    color: "#6275E7",
                                                    fontSize: 18,
                                                    fontFamily: "Lato",
                                                    fontWeight: "700",
                                                    wordWrap: "break-word",
                                                }}
                                            >
                                                Properties
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        style={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: 128,
                                        }}
                                        src="https://via.placeholder.com/40x40"
                                    />
                                </div> */}
                                {/* <Nav
                                    variant="underline"
                                    defaultActiveKey="/catalogs-manager"
                                >
                                    <Nav.Item>
                                        <Nav.Link href="/catalogs-manager">
                                            Active
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="/catalogs-manager/hotels-list">
                                            Option 2
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav> */}
                            </>
                        )}
                    </ul>
                    <ul className="navbar-nav float-right">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                style={{cursor: "pointer"}}
                                to="#"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <Avatar
                                    name={localStorage.getItem("userConnected")}
                                    size="45"
                                    round={true}
                                    textSizeRatio={1.75}
                                    color="#6275E7"
                                />
                            </span>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                    className="dropdown-item"
                                    onClick={async (evt) => {
                                        evt.preventDefault();
                                        localStorage.clear();
                                        removeCookie(
                                            "intercom-session-nhu68eka"
                                        );

                                        window.location =
                                            window.location.protocol +
                                            "//" +
                                            window.location.host +
                                            "/sign-in";
                                        // history.push("/sign-in");
                                        dispatch({ type: USER_LOGOUT });
                                    }}
                                >
                                    <i className="fas fa-sign-out-alt"></i>
                                    Logout
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

const NavItem = ({ to, label, selected }) => {
    return (
        <Link
            to={to}
            style={{
                width: "116px",
                height: "80px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "12px",
                paddingBottom: "12px",
                borderBottom: selected
                    ? "2px #6275E7 solid"
                    : "2px solid white",
                justifyContent: "center",
                alignItems: "center",
                display: "inline-flex",
            }}
        >
            <div
                style={{
                    textAlign: "center",
                    color: selected ? "#6275E7" : "#505573",
                    fontSize: "18px",
                    fontFamily: "Lato",
                    fontWeight: selected ? "700" : "400",

                    wordWrap: "break-word",
                }}
            >
                {label}
            </div>
        </Link>
    );
};

export default Header;
