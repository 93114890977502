import React, { useState, useEffect } from "react";
import { CUPID_MODEL, GIATA_MODEL } from "../utilis/const";
import { toast } from "react-toastify";
import {
    Modal,
    Tooltip,
    OverlayTrigger,
    Row,
    Col,
    Card,
    Spinner,
} from "react-bootstrap";
import PartnerCatalogService from "../services/PartnerCatalogService";
import GiataLogo from "../lotties/Giata-logo.svg";
import CupidLogo from "../lotties/cupid-logo.svg";
import GiataLogoGreyed from "../lotties/Giata_logo_greyed.svg";
import Select from "./Select";
import SyncLoader from "react-spinners/SyncLoader";
import {
    getCupidModels,
    getGiataPartnersList,
} from "../redux/actions/ModelsActions";
import Stepper from "react-stepper-horizontal";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { hasGiataCredentials } from "../redux/actions/ModelsActions";

const RunMappingModal = ({ open, handleClose, currentElement }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedModel, setSelectedModel] = useState(CUPID_MODEL);
    const [giataPartnerKey, setGiataPartnerKey] = useState();
    const [selectedCupidModel, setSelectedCupidModel] = useState();
    const [useManualMappings, setUseManualMappings] = useState(true);
    const [name, setName] = useState("");
    const dispatch = useDispatch();
    const { cupidModelsList } = useSelector((state) => ({
        cupidModelsList: state.modelsReducer.cupidModelsList?.models ?? [],
    }));

    const sections = React.useMemo(() => {
        const required = [
            {
                title: "Catalog Name",
            },
            {
                title: "Select Model",
            },
        ];

        return required;
    }, []);
    const next = async () => {
        if (currentPage === 1 && name === "") {
            toast.error("Catalog name is required to pass", {
                toastId: "name-catalog-csv-step1",
            });
        } else {
            setCurrentPage((prev) => prev + 1);
        }
    };
    const prev = () => setCurrentPage((prev) => prev - 1);
    useEffect(() => {
        dispatch(getCupidModels());
    }, []);

    useEffect(() => {
        if (cupidModelsList?.length > 0) {
            setSelectedCupidModel(cupidModelsList[0]);
        }
    }, [cupidModelsList]);

    const renderElement = () => {
        switch (currentPage) {
            case 1:
                return (
                    <TypeCatalogName
                        next={next}
                        name={name}
                        setName={setName}
                        handleClose={handleClose}
                    />
                );

            case 2:
                return (
                    <SelectModel
                        selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel}
                        prev={prev}
                        next={next}
                        giataPartnerKey={giataPartnerKey}
                        setGiataPartnerKey={setGiataPartnerKey}
                        selectedCupidModel={selectedCupidModel}
                        setSelectedCupidModel={setSelectedCupidModel}
                        handleClose={handleClose}
                        name={name}
                        currentElement={currentElement}
                        useManualMappings={useManualMappings}
                        setUseManualMappings={setUseManualMappings}
                    />
                );

            default:
                return <></>;
        }
    };

    return (
        <Modal
            show={open}
            onHide={handleClose}
            dialogClassName="modal-90w modal-dialog modal-dialog-centered"
        >
            <Modal.Body style={{ paddingBottom: "0px" }}>
                <div className="card-header p-0 pb-2">
                    <Stepper
                        steps={sections}
                        activeStep={currentPage}
                        activeColor="#D3D7DB"
                        defaultBarColor="#D3D7DB"
                        completeColor="#6076E8"
                        completeBarColor="#6076E8"
                        fontFamily="Roboto"
                        textColor="#fff"
                        defaultBorderStyle="solid 6px"
                        defaultBorderWidth={10}
                    />
                </div>
                <div className="card-body">{renderElement()}</div>
            </Modal.Body>
        </Modal>
    );
};

const SelectModel = (props) => {
    const {
        selectedModel,
        setSelectedModel,
        setGiataPartnerKey,
        giataPartnerKey,
        selectedCupidModel,
        setSelectedCupidModel,
        prev,
        handleClose,
        currentElement,
        name,
        useManualMappings,
        setUseManualMappings,
    } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const renderTooltip = (name) => (props) =>
        (
            <Tooltip id="button-tooltip" {...props}>
                {name}
            </Tooltip>
        );
    const {
        partnerKeys,
        hasGiataAccount,
        loadingHasGiataAccount,
        cupidModelsList,
    } = useSelector((state) => ({
        partnerKeys: state.modelsReducer.giataPartnerList?.data,
        hasGiataAccount: state.modelsReducer.hasGiataAccount?.has_account,
        loadingHasGiataAccount: state.modelsReducer.loadingHasGiataAccount,
        cupidModelsList: state.modelsReducer.cupidModelsList?.models ?? [],
        loadingCupidModelsList: state.modelsReducer.loadingCupidModelsList,
    }));

    useEffect(() => {
        dispatch(getGiataPartnersList());
        dispatch(hasGiataCredentials());
    }, []);

    const handleRunMapping = async () => {
        try {
            if (
                ((selectedModel === CUPID_MODEL &&
                    selectedCupidModel.run_giata === true) ||
                    selectedModel === GIATA_MODEL) &&
                !giataPartnerKey?.value
            ) {
                toast.error("Please select a giata key");
            } else {
                setLoading(true);
                const res =
                    await PartnerCatalogService.runPartnerInventoryMapping({
                        partner_inventory_id:
                            currentElement?.partner_inventory_id,
                        model:
                            selectedModel === CUPID_MODEL
                                ? selectedCupidModel?.name
                                : GIATA_MODEL,
                        giata_partner_key: giataPartnerKey?.value,
                        use_manual_mappings: useManualMappings,
                        name: name,
                        partner_id: currentElement.partner_id,
                    });
                if (res.status === 200) {
                    setLoading(false);
                    toast.success("Mapping started ");
                    handleClose();
                } else {
                    setLoading(false);
                    toast.error(res.message);
                }
            }
        } catch (err) {
            setLoading(false);
            toast.error(err ?? "Error while runing mapping");
        }
    };
    return (
        <>
            <Modal.Body style={{ textAlign: "center" }}>
                <div className="row">
                    {loadingHasGiataAccount ? (
                        <div className="col-md-12">
                            <div>
                                <SyncLoader
                                    color="#5856d694"
                                    loading={true}
                                    size={10}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="col-6">
                                <fieldset
                                    className="scheduler-border"
                                    style={{
                                        height: "100%",
                                        marginBottom: "0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "0 0.75em 0.75em 0.75em",
                                    }}
                                >
                                    <legend className="scheduler-border">
                                        <h3 className="card-title">
                                            Select model
                                        </h3>
                                    </legend>
                                    <div style={{ paddingRight: "20px" }}>
                                        <div
                                            style={{
                                                padding: "10px",
                                                border:
                                                    selectedModel ===
                                                    CUPID_MODEL
                                                        ? "1px solid rgb(96, 118, 232)"
                                                        : "1px solid rgb(0 0 0 / 23%)",
                                                borderRadius: "1rem",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setSelectedModel(CUPID_MODEL);
                                                setGiataPartnerKey(null);
                                            }}
                                            disabled={true}
                                        >
                                            <div
                                                className="card-body"
                                                style={{
                                                    padding:
                                                        "17px 13px 17px 13px",
                                                }}
                                            >
                                                <img
                                                    id={CUPID_MODEL}
                                                    alt={CUPID_MODEL}
                                                    src={CupidLogo}
                                                    width="150"
                                                    height="70"
                                                    onClick={() => {
                                                        setSelectedModel(
                                                            CUPID_MODEL
                                                        );
                                                        setGiataPartnerKey(
                                                            null
                                                        );
                                                    }}
                                                    style={{
                                                        padding: "10px",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="card-footer"
                                                style={{
                                                    borderRadius: "1rem",
                                                    backgroundColor:
                                                        selectedModel ===
                                                        CUPID_MODEL
                                                            ? "rgb(96, 118, 232)"
                                                            : "rgb(0 0 0 / 23%)",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                Cupid
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            borderRadius: "1rem",
                                        }}
                                    >
                                        <div
                                            className=""
                                            style={{
                                                padding: "10px",
                                                border:
                                                    selectedModel ===
                                                    GIATA_MODEL
                                                        ? "1px solid rgb(96, 118, 232)"
                                                        : "1px solid rgb(0 0 0 / 23%)",
                                                borderRadius: "1rem",
                                                cursor: "pointer",
                                                pointerEvents: hasGiataAccount
                                                    ? ""
                                                    : "none",
                                            }}
                                            onClick={() => {
                                                setSelectedModel(GIATA_MODEL);
                                                setSelectedCupidModel();
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{
                                                        show: 100,
                                                        hide: 100,
                                                    }}
                                                    overlay={renderTooltip(
                                                        "Beta Feature"
                                                    )}
                                                >
                                                    <span
                                                        className={`badge rounded-pill text-black bg-primary`}
                                                        style={{
                                                            color: "black",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        BETA
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <div
                                                className="card-body"
                                                style={{
                                                    padding:
                                                        "0px 13px 13px 13px",
                                                }}
                                            >
                                                <img
                                                    id={GIATA_MODEL}
                                                    alt={GIATA_MODEL}
                                                    src={
                                                        hasGiataAccount
                                                            ? GiataLogo
                                                            : GiataLogoGreyed
                                                    }
                                                    width="150"
                                                    height="70"
                                                    style={{
                                                        padding: "10px",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="card-footer"
                                                style={{
                                                    borderRadius: "1rem",
                                                    backgroundColor:
                                                        selectedModel ===
                                                        GIATA_MODEL
                                                            ? "rgb(96, 118, 232)"
                                                            : hasGiataAccount
                                                            ? "rgb(0 0 0 / 23%)"
                                                            : "#787878",
                                                    fontWeight: "bold",
                                                    color: "white",
                                                }}
                                            >
                                                {hasGiataAccount
                                                    ? "Giata"
                                                    : "Giata not configured"}
                                            </div>
                                        </div>
                                        {!hasGiataAccount && (
                                            <div
                                                style={{ alignItems: "center" }}
                                            >
                                                <button
                                                    className="btn text-link-button2"
                                                    onClick={() => {
                                                        history.push(
                                                            "/models/giata"
                                                        );
                                                    }}
                                                >
                                                    Configure here
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </fieldset>
                            </div>
                            {selectedModel === GIATA_MODEL && (
                                <div className="col-6">
                                    <fieldset
                                        className="scheduler-border"
                                        style={{
                                            height: "100%",
                                            marginBottom: "0px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 0.75em 0.75em 0.75em",
                                        }}
                                    >
                                        <legend className="scheduler-border">
                                            <h3 className="card-title">
                                                Select giata key
                                            </h3>
                                        </legend>
                                        <div
                                            className="form-group"
                                            style={{ width: "100%" }}
                                        >
                                            <Select
                                                options={partnerKeys?.map(
                                                    (e) => ({
                                                        value: e?.key,
                                                        label: e?.name,
                                                    })
                                                )}
                                                name="selectedPartner"
                                                value={giataPartnerKey}
                                                onChange={(e) => {
                                                    setGiataPartnerKey(e);
                                                }}
                                                isClearable={true}
                                                placeholder="Partner key"
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                            )}
                            {selectedModel === CUPID_MODEL && (
                                <div className="col-6">
                                    <fieldset
                                        className="scheduler-border"
                                        style={{
                                            height: "100%",
                                            marginBottom: "0px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0 0.75em 0.75em 0.75em",
                                        }}
                                    >
                                        <legend className="scheduler-border">
                                            <h3 className="card-title">
                                                Select model
                                            </h3>
                                        </legend>
                                        <Row
                                            style={{
                                                overflow: "auto",
                                                maxHeight: "200px",
                                                width: "100%",
                                            }}
                                        >
                                            {cupidModelsList?.map(
                                                (e, index) => {
                                                    return (
                                                        <Col md={12} lg={12}>
                                                            <Card
                                                                className="card-without-hover"
                                                                for={e?.id}
                                                                style={{
                                                                    border:
                                                                        e.id ===
                                                                        selectedCupidModel?.id
                                                                            ? "2px solid rgb(96, 118, 232)"
                                                                            : "",
                                                                    marginBottom:
                                                                        "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    setSelectedCupidModel(
                                                                        e
                                                                    );
                                                                }}
                                                            >
                                                                <Row className="no-gutters">
                                                                    <Col>
                                                                        <Card.Body
                                                                            style={{
                                                                                padding:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            <Card.Title
                                                                                style={{
                                                                                    display:
                                                                                        "flex",

                                                                                    justifyContent:
                                                                                        "space-between",
                                                                                }}
                                                                            >
                                                                                <label
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            "800",
                                                                                        fontSize:
                                                                                            "18px",
                                                                                        marginBottom:
                                                                                            "0px",
                                                                                    }}
                                                                                    for={
                                                                                        e?.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        e?.name
                                                                                    }
                                                                                </label>
                                                                            </Card.Title>
                                                                            <Card.Text
                                                                                className="card-text-mapping-details"
                                                                                style={{
                                                                                    textAlign:
                                                                                        "left",
                                                                                    marginTop:
                                                                                        "0px",
                                                                                }}
                                                                            >
                                                                                {e?.description ??
                                                                                    ""}
                                                                            </Card.Text>
                                                                        </Card.Body>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Col>
                                                    );
                                                }
                                            )}
                                        </Row>
                                    </fieldset>
                                </div>
                            )}
                            {selectedModel === CUPID_MODEL &&
                                selectedCupidModel?.run_giata === true && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <div className="col-6">
                                            <fieldset
                                                className="scheduler-border"
                                                style={{
                                                    height: "100%",
                                                    marginBottom: "0px",
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    padding:
                                                        "0 0.75em 0.75em 0.75em",
                                                }}
                                            >
                                                <legend className="scheduler-border">
                                                    <h3 className="card-title">
                                                        Select giata key
                                                    </h3>
                                                </legend>
                                                <div
                                                    className="form-group"
                                                    style={{ width: "100%" }}
                                                >
                                                    <Select
                                                        options={partnerKeys?.map(
                                                            (e) => ({
                                                                value: e?.key,
                                                                label: e?.name,
                                                            })
                                                        )}
                                                        name="selectedPartner"
                                                        value={giataPartnerKey}
                                                        onChange={(e) => {
                                                            setGiataPartnerKey(
                                                                e
                                                            );
                                                        }}
                                                        isClearable={true}
                                                        placeholder="Partner key"
                                                    />
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                )}
                        </>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                }}
            >
                <div
                    className="custom-control custom-checkbox"
                    style={{
                        marginRight: "35px",
                    }}
                >
                    <input
                        id={"usePreviousMapping"}
                        checked={useManualMappings}
                        onClick={() => {
                            setUseManualMappings(!useManualMappings);
                        }}
                        type="checkbox"
                        className="custom-control-input"
                        style={{
                            cursor: "pointer",
                        }}
                        // id="customCheck1"
                    />
                    <label
                        className="custom-control-label"
                        for="usePreviousMapping"
                    >
                        Use previous manual mapping
                    </label>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={prev}
                    >
                        <i className="fas fa-caret-left"></i> &nbsp;&nbsp; Back
                    </button>
                    <button
                        type="button"
                        className="btn btn-light"
                        onClick={handleClose}
                        style={{
                            marginLeft: "10px",
                        }}
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleRunMapping}
                        disabled={loading}
                        style={{
                            marginLeft: "10px",
                        }}
                    >
                        Start Mapping
                        {loading && (
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </div>
            </Modal.Footer>
        </>
    );
};

function TypeCatalogName(props) {
    const { next, name, setName, handleClose } = props;

    return (
        <>
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-6">
                        <fieldset
                            className="scheduler-border"
                            style={{ minHeight: "220px" }}
                        >
                            <legend className="scheduler-border">
                                <h3 className="card-title">
                                    Type your catalog name
                                </h3>
                            </legend>
                            <div className="form-group form-group-header">
                                <label>Catalog name </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Type your catalog name "
                                    name="name"
                                    value={name ?? ""}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-light"
                    onClick={handleClose}
                >
                    Close
                </button>
                <button className="btn btn-primary" onClick={next}>
                    Next &nbsp;&nbsp;
                    <i className="fas fa-caret-right"></i>
                </button>
            </Modal.Footer>{" "}
        </>
    );
}
export default RunMappingModal;
