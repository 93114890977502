import React, { useState, useEffect } from "react";
import LoginService from "../../../services/LoginService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import "../style.css";

function Step1({ register, errors, setStep, getValues, setValue }) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (getValues("email") == "") {
            setErrorMessage(true);
        } else {
            setErrorMessage(false);
        }
    }, [getValues("email")]);

    return (
        <>
            <div className="form-group">
                <label className="form-label" for="email">
                    Your work email
                </label>
                <input
                    type="email"
                    className="form-control2"
                    id="email"
                    placeholder="name@address.com"
                    autoComplete="off"
                    {...register("email")}
                />
                {errors.email && (
                    <small id="emailHelp" className="text-danger form-text">
                        {errors.email.message}
                    </small>
                )}
            </div>

            <button
                className="btn2 w-100 btn-primary2 mt-2"
                disabled={errors.email || loading}
                onClick={async () => {
                    if (getValues("email") == "") {
                        setErrorMessage(true);
                    } else {
                        setLoading(true);
                        let res = await LoginService.verifyEmail({
                            email: getValues("email"),
                        });
                        if (res.status === 200) {
                            setLoading(false);
                            setStep(2);
                        } else {
                            setLoading(false);
                            toast.error(res.message);
                        }
                        setErrorMessage(false);
                    }
                }}
            >
                Sign up
                {loading && (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
            </button>
        </>
    );
}
export default Step1;
