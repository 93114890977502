import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import "../style.css";
import { STYLE_INPUT } from "../../../utilis/const";

function Step4({
    register,
    errors,
    loading,
    selectedOption,
    setSelectedOption,
}) {
    // const selectOptions = [
    //     { value: 1, label: "500 - 1000" },
    //     { value: 2, label: "1000 - 50K" },
    //     { value: 3, label: "50K - 500K " },
    //     { value: 4, label: ">= 500K" },
    // ];
    const [showPassword, setShowPassword] = useState("password");
    return (
        <>
            <div className="row">
                <div className="col-6">
                    <div className="form-group">
                        <label className="form-label" for="firstName">
                            First Name
                        </label>
                        <input
                            type="text"
                            className="form-control2"
                            id="firstName"
                            placeholder="First Name"
                            autoComplete="off"
                            {...register("first_name")}
                        />
                        {errors.first_name && (
                            <small
                                id="emailHelp"
                                className="text-danger form-text"
                            >
                                {errors.first_name.message}
                            </small>
                        )}
                    </div>
                </div>

                <div className="col-6">
                    <div className="form-group">
                        <label className="form-label" for="LastName">
                            Last Name
                        </label>
                        <input
                            type="text"
                            className="form-control2"
                            id="LastName"
                            placeholder="Last Name"
                            autoComplete="off"
                            {...register("last_name")}
                        />
                        {errors.last_name && (
                            <small
                                id="emailHelp"
                                className="text-danger form-text"
                            >
                                {errors.last_name.message}
                            </small>
                        )}
                    </div>
                </div>
            </div>

            <div className="form-group mb-5">
                <label className="form-label" htmlFor="password">
                    Password
                </label>
                <div className="input-group">
                    <input
                        className="form-control"
                        type={showPassword}
                        id="password"
                        placeholder="Password"
                        {...register("password")}
                        style={{
                            ...STYLE_INPUT,
                        }}
                    />
                    <span
                        className="input-group-text"
                        style={{
                            border: "none",
                        }}
                    >
                        {showPassword === "password" ? (
                            <i
                                className="fa fa-eye"
                                id="togglePassword2"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => setShowPassword("text")}
                            ></i>
                        ) : (
                            <i
                                className="fas fa-eye-slash"
                                id="togglehidePassword2"
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() => setShowPassword("password")}
                            ></i>
                        )}
                    </span>
                </div>
                {errors.password && (
                    <small id="emailHelp" className="text-danger form-text">
                        {errors.password.message}
                    </small>
                )}
            </div>

            {/* <hr className="text-gray-300" /> */}

            {/* <div className="form-group mb-5">
                <label className="form-label" for="password">
                    How many hotels does your company have?
                </label>
                <select
                    className="form-select2"
                    value={selectedOption?.value}
                    onChange={(e) => {
                        setSelectedOption(e.target.value);
                    }}
                >
                    <option selected>Select...</option>
                    {selectOptions?.map((e) => {
                        return <option value={e.value}>{e?.label}</option>;
                    })}
                </select>
                {!selectedOption && (
                    <small id="emailHelp" className="text-danger form-text">
                        Please select an option
                    </small>
                )}
            </div> */}

            {/* <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="promotions"
                />
                <label className="form-check-label" for="promotions">
                    I'd like a Cupid specialist to walk me through the product
                </label>
            </div> */}

            <button
                className="btn2 w-100 btn-primary2"
                type="submit"
                disabled={loading}
            >
                Sign up{" "}
                {loading && (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
            </button>
        </>
    );
}
export default Step4;
