import React from "react";
import "../style.css";

function Step3({ register, errors, setStep, getValues }) {
    return (
        <>
            <div className="form-group">
                <label className="form-label" for="companyName">
                    Company Name
                </label>
                <input
                    type="text"
                    className="form-control2"
                    id="companyName"
                    placeholder="Company Name"
                    autoComplete="off"
                    {...register("brand_name")}
                />
                {errors.brand_name && (
                    <small className="text-danger form-text">
                        {errors.brand_name?.message ??
                            "Company name is required"}
                    </small>
                )}
            </div>

            <div className="form-group mb-5">
                <label className="form-label" for="legalName">
                    Legal Name
                </label>
                <input
                    type="text"
                    className="form-control2"
                    id="legalName"
                    placeholder="Legal Name"
                    {...register("legal_name")}
                />
            </div>

            <button
                className="btn2 w-100 btn-primary2 mt-2"
                onClick={() => {
                    if (getValues("brand_name") == "") {
                    } else {
                        setStep(4);
                    }
                }}
            >
                Next
            </button>
        </>
    );
}
export default Step3;
