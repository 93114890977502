import {
    GET_FILTERS_LIST_LISTING,
    GET_FILTERS_LIST_SUCCESS,
    GET_FILTERS_LIST_FAIL,
    GET_HOTELS_CANDIDATES_LISTING,
    GET_HOTELS_CANDIDATES_SUCCESS,
    GET_HOTELS_CANDIDATES_FAIL,
    GET_HOTELS_HISTORY_LISTING,
    GET_HOTELS_HISTORY_SUCCESS,
    GET_HOTELS_HISTORY_FAIL,
} from "../types";
import axios from "axios";

const PATH_URL = process.env.REACT_APP_BASE_URL;

export const getFiltersList = (mapping_id,token) => async (dispatch) => {
    dispatch({type: GET_FILTERS_LIST_LISTING})
    try {
        const res = await axios.get(
             !token ? `${PATH_URL}/manual-mapping/${mapping_id}/filters` : `${PATH_URL.replace("dashboard","")}external-manual-mapping/filters?token=${token}`
            ,{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
        );
        dispatch({
            type: GET_FILTERS_LIST_SUCCESS,
            payload: res.data,
        });
    } catch (e) {
        dispatch({type: GET_FILTERS_LIST_FAIL,});
    }
};
export const getHotelsCandidates =
    (mapping_id, filters, page, perPage, sort_by, sort_order,token) =>
    async (dispatch) => {
        var params = new URLSearchParams();
        Object.keys(filters)?.forEach((e) => {
            filters[e]?.forEach((c) => {
                params.append(e, c?.label);
            });
        });
        params.append("offset", (page - 1) * perPage);
        params.append("limit", perPage);
        if (sort_by) {
            params.append("sort_by", sort_by);
        }
        if (sort_order) {
            params.append("sort_order", sort_order);
        }

        dispatch({
            type: GET_HOTELS_CANDIDATES_LISTING,
        });
        try {
            const res = await axios.get(
                !token ? `${PATH_URL}/manual-mapping/${mapping_id}` : `${PATH_URL.replace("dashboard","")}external-manual-mapping?token=${token}`
                ,{
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                    params: params,
                }
            );
            dispatch({
                type: GET_HOTELS_CANDIDATES_SUCCESS,
                payload: res.data,
            });
        } catch (e) {
            dispatch({
                type: GET_HOTELS_CANDIDATES_FAIL,
            });
        }
    };

export const getMappingManualHistory =
    (partner_id, page, perPage, search,token) => async (dispatch) => {
        var params = new URLSearchParams();
        params.append("offset", page * perPage);
        params.append("limit", perPage);
        params.append("search", search);

        dispatch({
            type: GET_HOTELS_HISTORY_LISTING,
        });
        try {
            const res = await axios.get(
                !token ? `${PATH_URL}/manual-mapping/history/${partner_id}` : `${PATH_URL.replace("dashboard","")}external-manual-mapping/history?token=${token}`
                ,{
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                    params: params,
                }
            );
            dispatch({
                type: GET_HOTELS_HISTORY_SUCCESS,
                payload: res.data,
            });
        } catch (e) {
            dispatch({
                type: GET_HOTELS_HISTORY_FAIL,
            });
        }
    };
