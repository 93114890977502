import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import LoginService from "../../../services/LoginService";
import { toast } from "react-toastify";

function Step2({ register, errors, setStep, getValues, setValue }) {
    const [errorMessage, setErrorMessage] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (getValues("verification_code") == "") {
            setErrorMessage(true);
        } else {
            setErrorMessage(false);
        }
    }, [getValues("verification_code")]);
    return (
        <>
            <div className="form-group">
                <label className="form-label" for="verification_code">
                    Verification Code
                </label>
                <input
                    type="text"
                    className="form-control2"
                    id="verification_code"
                    autoComplete="off"
                    placeholder="Verification Code"
                    {...register("verification_code")}
                />
                {errors.verification_code && (
                    <small id="emailHelp" className="text-danger form-text">
                        {errors.verification_code.message}
                    </small>
                )}
            </div>

            <button
                className="btn2 w-100 btn-primary2 mt-2"
                disabled={errors.email || loading}
                onClick={async () => {
                    if (getValues("verification_code") == "") {
                        setErrorMessage(true);
                    } else {
                        setLoading(true);

                        let resVerification =
                            await LoginService.verifyCodeEmail(
                                getValues("verification_code")
                            );
                        if (resVerification.status === 200) {
                            setLoading(false);
                            setStep(3);
                        } else {
                            setLoading(false);
                            toast.error(resVerification.message);
                        }
                        setErrorMessage(false);
                    }
                }}
            >
                Confirm
                {loading && (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
            </button>
        </>
    );
}
export default Step2;
