import React, { useEffect, useState } from "react";
import { separator, hasPermission } from "../../utilis/functions";
import { MANAGE_DUPLICATES } from "../../utilis/const";
import moment from "moment";
import { Spinner, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DownloadCatalog from "./DownloadCatalog";
import ConnectorService from "../../services/ConnectorService";
import ConfirmAction from "../../components/ConfirmActionsModal";
import { ToastContainer, toast } from "react-toastify";
import Form from "@rjsf/core";
import { useDispatch, useSelector } from "react-redux";
import CatalogService from "../../services/CatalogService";
import { getConnectors } from "../../redux/actions/ConnectorActions";
import { getCatalogs, getCatalog } from "../../redux/actions/CatalogActions";
import CSV_LOGO from "../../lotties/csv-upload.png";
import DuplicateService from "../../services/DuplicateService";

const FinishProcessingCatalog = (props) => {
    const { catalog, activeCatalog } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const [showRerunInventoryModal, setShowRerunInventoryModal] =
        useState(false);
    const [loadingRunInventory, setLoadingRunInventory] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [connector, setConnector] = useState();
    const [connectorForm, setConnectorForm] = useState({});
    const [connectorUiSchema, setConnectorUiSchema] = useState({});
    const [dataCredential, setDataCredential] = useState({});
    const [NumberDuplicate, setNumberDuplicate] = useState(
        activeCatalog.GroupCount
    );
    const { loadingInventoryConnectorCredentails, connectors } = useSelector(
        (state) => ({
            inventoryConnectorCredentials:
                state.catalogReducer.inventoryConnectorCredentials,
            loadingInventoryConnectorCredentails:
                state.catalogReducer.loadingInventoryConnectorCredentails,
            loadingCridentialsById:
                state?.connectorReducer?.loadingCridentialsById,
            connectors: state?.connectorReducer?.listConnectors ?? [],
        })
    );
    const [updateInventoryDaily, setUpdateInventoryDaily] = useState();
    const renderTooltip = (props = (text) => {
        return (
            <Tooltip id={`button-tooltip ${text}`} {...props}>
                {text}
            </Tooltip>
        );
    });
    useEffect(() => {
        dispatch(getConnectors());
    }, []);
    const handleRunInventory = async () => {
        setLoadingRunInventory(true);
        const res = await ConnectorService.rerunInventory();
        if (res.status === 200) {
            dispatch(getCatalogs());
        } else {
            toast.error(res.message);
        }
        setShowRerunInventoryModal(false);
        setLoadingRunInventory(false);
    };
    const handleEdit = async () => {
        setUpdateInventoryDaily(catalog.is_scheduled);
        const res = await CatalogService.getInventoryConnectorCredentialsById(
            catalog?.connector_credentials_id
        );
        if (res.status === 200) {
            let connector = await connectors?.find(
                (e) => e?.id === res?.data?.connector_id
            );
            setConnector(connector);
            const parsedData = await testt(connector?.form, res.credentials);
            setConnectorForm(parsedData);
            setDataCredential(res.data);
            setConnectorUiSchema(connector?.ui_form_schema);
            setShowEditModal(true);
        }
    };
    const testt = async (form, dd) => {
        var parsedData = form ? JSON.parse(form) : {};
        var keys = Object.keys(parsedData["properties"]);
        keys?.forEach((e) => {
            const tt = Object.keys(parsedData["properties"])?.find(
                (d) => d === e
            );
            parsedData["properties"][e] = {
                ...parsedData["properties"][e],
                default: dd?.[tt],
            };
        });
        return parsedData;
    };
    const [duplicatePorcentage, setDuplicatePorcentage] = useState(
        catalog.Progress
    );
    const [duplicateState, setduplicateState] = useState(
        catalog.duplicate_status_id
    );
    const [loadingrunDuplicate, setloadingrunDuplicate] = useState(false);

    const detectDuplicate = async () => {
        setloadingrunDuplicate(true);
        const runDuplicate = await DuplicateService.runDuplicate();
        if (runDuplicate) {
            setloadingrunDuplicate(false);
            if (runDuplicate.status == 200) {
                toast.success(runDuplicate.data, {
                    toastId: runDuplicate.message,
                });
                setDuplicatePorcentage(0);
                setduplicateState(1);
            } else {
                toast.error(runDuplicate.message, {
                    toastId: runDuplicate.message,
                });
            }
        }
    };
    const refreshDuplicate = async () => {
        setloadingrunDuplicate(true);
        const refreshDuplicate = await DuplicateService.refreshDuplicate();
        if (refreshDuplicate) {
            setloadingrunDuplicate(false);
            if (refreshDuplicate.status == 200) {
                setDuplicatePorcentage(refreshDuplicate.data.Progress);
                setduplicateState(refreshDuplicate.data.Status);
                if (refreshDuplicate.data.Status == 2) {
                    setNumberDuplicate(refreshDuplicate.data.GroupCount);
                }
            } else {
                toast.error(refreshDuplicate.message, {
                    toastId: refreshDuplicate.message,
                });
            }
        }
    };
    if (!catalog) {
        return (
            <p className="AnyActiveCatalog">
                You dont have any active inventory
            </p>
        );
    }
    return (
        <>
            <ToastContainer />
            <div className="InventeryCardGlobal">
                <div className="InventeryCardGlobalTitle">
                    <h4 className="card-title">My inventory</h4>
                    <div className="InventeryCardGlobalTitleIcons">
                        {catalog?.is_scheduled === true && (
                            <>
                                <div
                                    className="integration-entity "
                                    style={{
                                        marginBottom: "0px",
                                        marginTop: "45px",
                                        marginLeft: "0px",
                                        marginRight: "5px",
                                    }}
                                >
                                    <div className="integration-entity-name"></div>

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip("Run inventory")}
                                    >
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={async () => {
                                                setShowRerunInventoryModal(
                                                    true
                                                );
                                            }}
                                        >
                                            <i
                                                className="far fa-play-circle"
                                                style={{
                                                    color: "#21CA7F",
                                                    fontSize: "20px",
                                                }}
                                            ></i>
                                            {false && (
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </button>
                                    </OverlayTrigger>
                                </div>
                                <div
                                    className="integration-entity "
                                    style={{
                                        marginBottom: "0px",
                                        marginLeft: "0px",
                                        marginRight: "10px",
                                        marginTop: "45px",
                                    }}
                                >
                                    <div className="integration-entity-name"></div>

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip("Edit")}
                                    >
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleEdit}
                                        >
                                            {loadingInventoryConnectorCredentails ? (
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <i
                                                    className="far fa-edit"
                                                    style={{
                                                        color: "#FCB144",
                                                        fontSize: "20px",
                                                    }}
                                                ></i>
                                            )}
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            </>
                        )}

                        {catalog?.connector_credentials_id > 0 && (
                            <>
                                <div
                                    className="integration-entity "
                                    style={{
                                        marginBottom: "0px",
                                        marginTop: "45px",
                                        marginLeft: "0px",
                                        marginRight: "5px",
                                    }}
                                >
                                    <div className="integration-entity-name"></div>

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip("Run inventory")}
                                    >
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={async () => {
                                                setShowRerunInventoryModal(
                                                    true
                                                );
                                            }}
                                        >
                                            <i
                                                className="far fa-play-circle"
                                                style={{
                                                    color: "#21CA7F",
                                                    fontSize: "20px",
                                                }}
                                            ></i>
                                            {false && (
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </button>
                                    </OverlayTrigger>
                                </div>
                                <div
                                    className="integration-entity "
                                    style={{
                                        marginBottom: "0px",
                                        marginLeft: "0px",
                                        marginRight: "10px",
                                        marginTop: "45px",
                                    }}
                                >
                                    <div className="integration-entity-name"></div>

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip("Edit")}
                                    >
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleEdit}
                                        >
                                            {loadingInventoryConnectorCredentails ? (
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <i
                                                    className="far fa-edit"
                                                    style={{
                                                        color: "#FCB144",
                                                        fontSize: "20px",
                                                    }}
                                                ></i>
                                            )}
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="cardInventory">
                    <div className="InfoInventory1">
                        <div className="InfoInventory1Title">
                            <p className="nameOfInventory">{catalog?.name}</p>
                            <DownloadCatalog
                                dataCell={catalog}
                                styleInventory={true}
                            />
                        </div>
                        <p className="text-dark fs-6 fw-medium font-family-Lato m-0  propertiesoFInventory">
                            {catalog?.total_hotels
                                ? separator(catalog?.total_hotels)
                                : 0}{" "}
                            properties
                        </p>
                        <p className="py-2 dateoFInventory">
                            {moment(new Date(catalog?.created_at)).format(
                                "LLL"
                            )}
                        </p>
                    </div>
                    <div className="InfoInventory2">
                        <div className="InfoInventory2-centent">
                            <div className="Item">
                                <div className="ItemTitle">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            width="24"
                                            height="24"
                                            rx="12"
                                            fill="#ECFDF3"
                                        />
                                        <path
                                            d="M17.3334 8L10 15.3333L6.66669 12"
                                            stroke="#039855"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    <p className="Title">Valid</p>
                                </div>
                                <p className="Info">
                                    {catalog?.valid_hotels
                                        ? separator(catalog?.valid_hotels)
                                        : 0}
                                </p>
                            </div>
                            <div className="Item">
                                <div className="ItemTitle">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            width="24"
                                            height="24"
                                            rx="12"
                                            fill="#FEF3F2"
                                        />
                                        <path
                                            d="M16 8L8 16M8 8L16 16"
                                            stroke="#F04438"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                    <p className="Title">Invalid</p>
                                </div>
                                <div
                                    className="Refresh"
                                    onClick={() => {
                                        catalog?.invalid_hotels > 0 &&
                                            history.push(
                                                `/catalogs-manager/invalide?id=${catalog.id}&offset=0&limit=10`
                                            );
                                    }}
                                >
                                    <p className="Info">
                                        {catalog?.invalid_hotels
                                            ? separator(catalog?.invalid_hotels)
                                            : 0}
                                    </p>
                                    {catalog?.invalid_hotels > 0 ? (
                                        <p class="text-purple fs-6 fw-medium font-family-Lato text-decoration-underline  m-0 p-0  refreshTxt">
                                            manage
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="Item itemLast">
                                <div className="ItemTitle">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect
                                            width="24"
                                            height="24"
                                            rx="12"
                                            fill="#FFF7E2"
                                        />
                                        <g clip-path="url(#clip0_649_270)">
                                            <path
                                                d="M6.66665 14.6668C5.93331 14.6668 5.33331 14.0668 5.33331 13.3335V6.66683C5.33331 5.9335 5.93331 5.3335 6.66665 5.3335H13.3333C14.0666 5.3335 14.6666 5.9335 14.6666 6.66683M10.6666 9.3335H17.3333C18.0697 9.3335 18.6666 9.93045 18.6666 10.6668V17.3335C18.6666 18.0699 18.0697 18.6668 17.3333 18.6668H10.6666C9.93027 18.6668 9.33331 18.0699 9.33331 17.3335V10.6668C9.33331 9.93045 9.93027 9.3335 10.6666 9.3335Z"
                                                stroke="#FDB022"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_649_270">
                                                <rect
                                                    width="16"
                                                    height="16"
                                                    fill="white"
                                                    transform="translate(4 4)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p className="Title">Duplicates</p>
                                </div>
                                {duplicateState != 0 ? (
                                    <div
                                        className="Refresh"
                                        onClick={() => {
                                            if (!loadingrunDuplicate) {
                                                if (duplicateState == 1) {
                                                    refreshDuplicate();
                                                } else
                                                    history.push(
                                                        `catalogs-manager/duplicate?type=automatic&id=${catalog.id}`
                                                    );
                                            }
                                        }}
                                    >
                                        {duplicateState == 1 ? (
                                            <>
                                                <div
                                                    class="spinner-border"
                                                    role="status"
                                                ></div>
                                                <span class="text-secondary fs-6 fw-normal font-family-Lato  m-0 ">
                                                    {Math.floor(
                                                        duplicatePorcentage
                                                    ) == 100
                                                        ? 99
                                                        : Math.floor(
                                                              duplicatePorcentage
                                                          )}
                                                    %
                                                </span>
                                                <p class="text-purple fs-6 fw-medium font-family-Lato text-decoration-underline  m-0 p-0  refreshTxt">
                                                    refresh
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="Info">
                                                    {NumberDuplicate}
                                                </p>
                                                <p class="text-purple fs-6 fw-medium font-family-Lato text-decoration-underline  m-0 p-0  refreshTxt">
                                                    manage
                                                </p>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip(
                                            "Managing your duplicates improves the quality of your database"
                                        )}
                                    >
                                        <div
                                            className="BTnDetect"
                                            onClick={() => {
                                                !loadingrunDuplicate &&
                                                    detectDuplicate();
                                            }}
                                        >
                                            {!loadingrunDuplicate ? (
                                                <>
                                                    Detect
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M8 10.8V8M8 5.2H8.007M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                                                            stroke="#6275E7"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </>
                                            ) : (
                                                <div
                                                    class="spinner-border"
                                                    role="status"
                                                ></div>
                                            )}
                                        </div>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="InfoInventory3">
                        {catalog?.connector_credentials_id > 0 ? (
                            <div
                                className="integration-entity"
                                style={{
                                    marginBottom: "0px",
                                    marginLeft: "20px",
                                    marginRight: "30px",
                                }}
                            >
                                <div className="integration-entity-name">
                                    Connector
                                </div>

                                <div
                                    className="integration-entity-img-wrapper-connector-inventory"
                                    style={{
                                        margin: "0px",
                                    }}
                                >
                                    <img
                                        id={"connector_logo"}
                                        className="cloudinary-image ls-is-cached lazyloaded round-img"
                                        alt={"connector_logo"}
                                        src={catalog?.connector_logo}
                                        width="60"
                                        height="60"
                                    />
                                </div>
                            </div>
                        ) : Object.keys(catalog)?.length > 0 ? (
                            <div
                                className="integration-entity"
                                style={{
                                    marginBottom: "0px",
                                    marginLeft: "20px",
                                    marginRight: "30px",
                                }}
                            >
                                <div className="integration-entity-name">
                                    CSV
                                </div>

                                <div
                                    className="integration-entity-img-wrapper-connector-inventory"
                                    style={{
                                        margin: "0px",
                                    }}
                                >
                                    <img
                                        id={"csv_logo"}
                                        className="cloudinary-image ls-is-cached lazyloaded round-img"
                                        alt={"CSV_logo"}
                                        src={CSV_LOGO}
                                        width="60"
                                        height="60"
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {catalog?.is_scheduled === true ? (
                            <div
                                className="integration-entity "
                                style={{
                                    marginBottom: "0px",
                                    marginLeft: "0px",
                                    marginRight: "30px",
                                }}
                            >
                                <div className="integration-entity-name">
                                    Scheduler
                                </div>

                                <div
                                    className="integration-entity-img-wrapper-connector-inventory"
                                    style={{
                                        margin: "0px",

                                        fontSize: "15px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Daily
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            {/* <div className="col-6">
                <h4 style={{ marginTop: "0.5rem" }}>{catalog?.name}</h4>
            </div> */}
            {/* <div
                className="col-6"
                style={{
                    textAlign: "right",
                    paddingTop: "5px",
                }}
            >
                <div className="ml-auto">
                    <div className="">
                        {!hasPermission(MANAGE_DUPLICATES) ? (
                            <></>
                        ) : catalog?.detecting_dups ? (
                            <button
                                type="button"
                                className="btn btn-sm waves-effect waves-light btn-rounded btn-outline-secondary"
                                style={{
                                    marginRight: "4px",
                                }}
                                disabled={true}
                            >
                                <Spinner
                                    animation="border"
                                    variant="primary"
                                    size="sm"
                                />
                                Detecting duplicates
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-sm waves-effect waves-light btn-rounded btn-outline-secondary"
                                onClick={() => {
                                    history.push("/catalogs-manager/duplicate");
                                }}
                                style={{
                                    marginRight: "8px",
                                }}
                            >
                                Manage duplicates
                            </button>
                        )}

                        <DownloadCatalog dataCell={catalog} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <p>
                    <i className="fas fa-building"></i>{" "}
                    {catalog?.total_hotels
                        ? separator(catalog?.total_hotels)
                        : 0}
                    &nbsp;Properties
                    <OverlayTrigger
                        placement="bottom"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip("Includes the required fields")}
                    >
                        <span className="title" style={{ fontSize: "15px" }}>
                            <span
                                className="number"
                                style={{ fontWeight: "bold" }}
                            >
                                &nbsp; Valid :&nbsp;
                            </span>

                            {catalog?.valid_hotels
                                ? separator(catalog?.valid_hotels)
                                : 0}
                        </span>
                    </OverlayTrigger>
                    &nbsp;|
                    <OverlayTrigger
                        placement="bottom"
                        delay={{
                            show: 250,
                            hide: 400,
                        }}
                        overlay={renderTooltip(
                            "Missing one or more required fields (Hotel Name, Hotel Address, Country, Latitude and/or Longitude)"
                        )}
                    >
                        <span className="title" style={{ fontSize: "15px" }}>
                            <span
                                className="number"
                                style={{ fontWeight: "bold" }}
                            >
                                &nbsp; Invalid :&nbsp;
                            </span>

                            {catalog?.invalid_hotels
                                ? separator(catalog?.invalid_hotels)
                                : 0}
                        </span>
                    </OverlayTrigger>
                </p>
                <p>
                    <i className="fas fa-calendar"></i>{" "}
                    {moment(new Date(catalog?.created_at)).format(
                        "DD/MM/YYYY HH:mm:ss"
                    )}
                </p>
            </div> */}

            {/* {catalog?.is_scheduled === true && (
                <div
                    className="integration-entity "
                    style={{
                        marginBottom: "0px",
                        marginLeft: "0px",
                        marginRight: "30px",
                    }}
                >
                    <div className="integration-entity-name">Scheduler</div>

                    <div
                        className="integration-entity-img-wrapper-connector-inventory"
                        style={{
                            margin: "0px",

                            fontSize: "15px",
                            fontWeight: "500",
                        }}
                    >
                        Daily
                    </div>
                </div>
            )}
                 <>
                    <div
                        className="integration-entity "
                        style={{
                            marginBottom: "0px",
                            marginTop: "45px",
                            marginLeft: "0px",
                            marginRight: "5px",
                        }}
                    >
                        <div className="integration-entity-name"></div>

                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip("Run inventory")}
                        >
                            <button
                                type="button"
                                className="btn"
                                onClick={async () => {
                                    setShowRerunInventoryModal(true);
                                }}
                            >
                                <i
                                    className="far fa-play-circle"
                                    style={{
                                        color: "#21CA7F",
                                        fontSize: "20px",
                                    }}
                                ></i>
                                {false && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </OverlayTrigger>
                    </div>
                    <div
                        className="integration-entity "
                        style={{
                            marginBottom: "0px",
                            marginLeft: "0px",
                            marginRight: "10px",
                            marginTop: "45px",
                        }}
                    >
                        <div className="integration-entity-name"></div>

                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip("Edit")}
                        >
                            <button
                                type="button"
                                className="btn"
                                onClick={handleEdit}
                            >
                                {loadingInventoryConnectorCredentails ? (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <i
                                        className="far fa-edit"
                                        style={{
                                            color: "#FCB144",
                                            fontSize: "20px",
                                        }}
                                    ></i>
                                )}
                            </button>
                        </OverlayTrigger>
                    </div>
                </>
            {catalog?.connector_credentials_id > 0 && (
                <>
                    <div
                        className="integration-entity "
                        style={{
                            marginBottom: "0px",
                            marginTop: "45px",
                            marginLeft: "0px",
                            marginRight: "5px",
                        }}
                    >
                        <div className="integration-entity-name"></div>

                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip("Run inventory")}
                        >
                            <button
                                type="button"
                                className="btn"
                                onClick={async () => {
                                    setShowRerunInventoryModal(true);
                                }}
                            >
                                <i
                                    className="far fa-play-circle"
                                    style={{
                                        color: "#21CA7F",
                                        fontSize: "20px",
                                    }}
                                ></i>
                                {false && (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </OverlayTrigger>
                    </div>
                    <div
                        className="integration-entity "
                        style={{
                            marginBottom: "0px",
                            marginLeft: "0px",
                            marginRight: "10px",
                            marginTop: "45px",
                        }}
                    >
                        <div className="integration-entity-name"></div>

                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip("Edit")}
                        >
                            <button
                                type="button"
                                className="btn"
                                onClick={handleEdit}
                            >
                                {loadingInventoryConnectorCredentails ? (
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <i
                                        className="far fa-edit"
                                        style={{
                                            color: "#FCB144",
                                            fontSize: "20px",
                                        }}
                                    ></i>
                                )}
                            </button>
                        </OverlayTrigger>
                    </div>
                </>
            )} */}

            {showRerunInventoryModal && (
                <ConfirmAction
                    show={showRerunInventoryModal}
                    onClose={() => setShowRerunInventoryModal(false)}
                    titleMessage="Warning !"
                    subTitleMessage="Are you sure you want run inventory?"
                    colorCode="danger"
                    handleClick={handleRunInventory}
                    buttonText="Confirm"
                    disabled={loadingRunInventory}
                />
            )}
            {showEditModal && (
                <UpdateCredentials
                    show={showEditModal}
                    currentElement={catalog}
                    onClose={() => setShowEditModal(false)}
                    uiSchema={connectorUiSchema}
                    form={connectorForm}
                    dataCredential={dataCredential}
                    connector={connector}
                    updateInventoryDaily={updateInventoryDaily}
                    setUpdateInventoryDaily={setUpdateInventoryDaily}
                    catalog={catalog}
                />
            )}
        </>
    );
};
export default FinishProcessingCatalog;

function UpdateCredentials(props) {
    const {
        show,
        onClose,
        currentElement,
        uiSchema,
        form,
        connector,
        updateInventoryDaily,
        setUpdateInventoryDaily,
        catalog,
    } = props;
    const dispatch = useDispatch();
    const [loadingTestConnection, setLoadingTestConnection] = useState(false);
    const [formData, setFormData] = React.useState({});
    const [loadingUpdateCredentials, setLoadingUpdateCredentials] =
        useState(false);

    const handleSubmit = async (values) => {
        setLoadingUpdateCredentials(true);
        try {
            const res =
                await CatalogService.updateConnectorInventoryCredentials(
                    currentElement?.connector_credentials_id,
                    JSON.stringify(values?.formData),
                    updateInventoryDaily,
                    catalog?.id
                );
            if (res.status === 200) {
                //dispatch(getCredentials());
                setLoadingUpdateCredentials(false);
                onClose();
                dispatch(getCatalog(catalog?.id));
                //  await dispatch(getCredentials());
            } else {
                toast.error(res.message);
            }
            setLoadingUpdateCredentials(false);
        } catch (e) {
            console.error(e);
            setLoadingUpdateCredentials(false);
        }
    };

    const onChange = React.useCallback(({ formData }) => {
        setFormData(formData);
    }, []);

    const handleTestConnection = async () => {
        setLoadingTestConnection(true);
        try {
            const res = await ConnectorService.connectorTestConnection(
                connector?.id,
                formData
            );
            if (res.status === 200) {
                setLoadingTestConnection(false);
                toast.success(res?.data?.msg);
            } else {
                setLoadingTestConnection(false);
                toast.error(res?.message);
            }
        } catch {
            setLoadingTestConnection(false);
            toast.error("Error establishing connection");
        }
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="modal-50w modal-dialog modal-dialog-centered"
        >
            <div className="card mb-0 p-10">
                <Modal.Body>
                    <Form
                        schema={form}
                        uiSchema={uiSchema ? JSON.parse(uiSchema) : {}}
                        onSubmit={handleSubmit}
                        onError={() => console.log("Error")}
                        onChange={onChange}
                        formData={formData} // Pass the form data to the component
                    >
                        <Modal.Footer
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                            }}
                        >
                            <div
                                className="custom-control custom-checkbox"
                                style={{
                                    marginRight: "35px",
                                    marginBottom: "20px",
                                }}
                            >
                                <>
                                    <input
                                        id={"updateInventoryDaily"}
                                        checked={updateInventoryDaily}
                                        onClick={() => {
                                            setUpdateInventoryDaily(
                                                !updateInventoryDaily
                                            );
                                        }}
                                        type="checkbox"
                                        className="custom-control-input"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        // id="customCheck1"
                                    />
                                    <label
                                        className="custom-control-label"
                                        for="updateInventoryDaily"
                                    >
                                        Update my inventory daily
                                    </label>
                                </>
                            </div>
                            <div>
                                <button
                                    className="btn waves-effect waves-light btn-rounded btn-warning"
                                    onClick={() => {
                                        onClose();
                                    }}
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    Close
                                </button>
                                <button
                                    onClick={handleTestConnection}
                                    className="btn btn-success"
                                    disabled={loadingTestConnection}
                                >
                                    Test connection
                                    {loadingTestConnection && (
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </button>
                                <button
                                    type="submit"
                                    disabled={loadingUpdateCredentials}
                                    className="btn waves-effect waves-light btn-rounded btn-primary"
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    Submit
                                    {loadingUpdateCredentials && (
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </div>
        </Modal>
    );
}
