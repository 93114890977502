import React from "react";
import { Route, Switch, Redirect ,useLocation} from "react-router-dom";
import getRoutes from "../routes";
import Header from "../components/Header";
import Leftside from "../components/Leftside";
import SignIn from "../pages/Login/SignIn";
import ConfirmPassword from "../pages/Login/ConfirmPassword";
import SignUp from "../pages/Login/Sign-up/index";
import TeamSignUp from "../pages/Login/TeamSign-up";
import PartnerReport from "../pages/Partner-Report/index";

export default function DashboardRouter(props) {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const routes = getRoutes();
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    
    return (
        <>
            {/* <Route path="/partner-access/sign-in" component={PartnerSignIn} /> */}
            <Route path="/sign-in" render={() => <SignIn {...props} />} />
            <Route path="/sign-up" render={() => <SignUp {...props} />} />
            <Route
                path="/team-signup"
                render={() => <TeamSignUp {...props} />}
            />
            <Route
                path="/resetPassword/cofirm/:token"
                render={() => <ConfirmPassword {...props} />}
            />
            <Route
                path="/partner-reports"
                render={() => <PartnerReport {...props} />}
            />
            <Route exact path="/">
                {isAuthenticated ? (
                    <Redirect to="/home" />
                ) : (
                    <Redirect to="/sign-in" />
                )}
            </Route>
            <Switch>
                {routes.map((route, key) => {
                    const token = queryParams.get('token');
                    if(location.pathname.includes('external-manual-mapping') && token){
                        return(
                            <Route
                                path={route.path}
                                render={() =><route.component {...props} />}
                                key={key}
                            ></Route>
                        )
                    }
                    return (
                        <Route
                            path={route.path}
                            render={() =>
                                isAuthenticated ? (
                                    <>
                                        <Header />
                                        <Leftside {...props} />
                                        <route.component {...props} />
                                    </>
                                ) : (
                                    <Redirect to="/sign-in" />
                                )
                            }
                            key={key}
                        ></Route>
                    );
                })}
            </Switch>
        </>
    );
}
