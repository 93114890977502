import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import ManualMappingService from "../../../services/ManualMappingService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getHotelsCandidates } from "../../../redux/actions/ManualMappingActions";
import CardMapHotel from "./CandidateMaps";
import {
    LinkSvg,
    SVGSubmitIcon,
    MediumConfidenceSVG,
    HighConfidenceSVG,
    LowConfidenceSVG,
    MarkerSVG,
    AlertSVG,
    CloseButtonSVG,
    SVGGoogleMarkerIcon,
} from "./Svgs";

const HIGH_CONIDENCE = "High Confidence";
const MEDIUM_CONIDENCE = "Medium Confidence";
const LOW_CONFIDENCE = "Low Confidence";

const RenderProba = ({ proba }) => {
    let status = "";
    let perProba = proba * 100;

    if (perProba >= 90) {
        status = HIGH_CONIDENCE;
    } else if (perProba < 90 && perProba >= 80) {
        status = MEDIUM_CONIDENCE;
    } else if (perProba < 80 && perProba >= 50) {
        status = LOW_CONFIDENCE;
    }
    switch (status) {
        case HIGH_CONIDENCE:
            return <HighConfidenceSVG />;
        case MEDIUM_CONIDENCE:
            return <MediumConfidenceSVG />;
        case LOW_CONFIDENCE:
            return <LowConfidenceSVG />;
        default:
            return <></>;
    }
};

const HotelsCandidatesListModal = (props) => {
    const {
        data,
        show,
        handleClose,
        catalog_id,
        perPage,
        page,
        filters,
        mappedHotel,
        token
    } = props;
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState();
    const [loadingMapCondidate, setLoadingMapCandidate] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    const [center, setCenter] = useState({
        lat: data.latitude,
        lng: data.longitude,
    });

    const handleCardClick = (row) => {
        setSelectedItem({
            ...row,
            candidate_hotel_code: row?.hotel_code,
            hotel_code: data?.hotel_code,
        });
        setCenter({ lat: row.latitude, lng: row.longitude });
        setActiveMarker({
            lat: data.latitude,
            lng: data.longitude,
        });
    };

    React.useEffect(() => {
        if (
            !selectedItem &&
            data?.candidates?.sort((a, b) => b.proba - a.proba)?.[0]
        ) {
            setSelectedItem({
                ...data?.candidates?.sort((a, b) => b.proba - a.proba)?.[0],
                candidate_hotel_code: data?.candidates?.sort(
                    (a, b) => b.proba - a.proba
                )?.[0]?.hotel_code,
                hotel_code: data?.hotel_code,
                mapped_manually: mappedHotel?.mapped_manually,
            });
        }
    }, [selectedItem]);

    const handleSubmit = async () => {
        try {
            setLoadingMapCandidate(true);
            const res = await ManualMappingService.mapCondidate(catalog_id, [selectedItem],token);
            if (res.status === 200) {
                toast.success("Mapped");
                setLoadingMapCandidate(false);
                handleClose();
                dispatch(
                    getHotelsCandidates(catalog_id, filters, page, perPage,null,null,token)
                );
            } else {
                toast.error(res?.message);
                setLoadingMapCandidate(false);
            }
        } catch (e) {
            setLoadingMapCandidate(false);
        }
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName="modal-80w modal-dialog modal-dialog-centered"
        >
            <CandidateHeader handleClose={handleClose} />
            <CandidateHeaderCards data={data} selectedItem={selectedItem} />
            <div
                style={{
                    width: "100%",
                    height: "24px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "8px",
                    display: "inline-flex",
                }}
            >
                <div
                    style={{
                        color: "#141933",
                        fontSize: "18px",
                        fontFamily: "Lato",
                        fontWeight: "700",
                        lineHeight: "24px",
                        wordWrap: "break-word",
                    }}
                >
                    {`${data?.candidates?.length} Opportunities`}
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    minHeight: "446px",
                    padding: "24px",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "24px",
                    display: "inline-flex",
                }}
            >
                <div
                    style={{
                        flex: "1 1 0",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        gap: "16px",
                        display: "inline-flex",
                        width: "50%",
                        overflow: "scroll",
                        maxHeight: "400px",
                    }}
                >
                    {data?.candidates
                        ?.sort((a, b) => b.proba - a.proba)
                        ?.slice(0, 9)
                        ?.map((e, index) => {
                            return (
                                <CandidateCard
                                    candidate={e}
                                    index={index}
                                    selectedItem={selectedItem}
                                    handleCardClick={handleCardClick}
                                />
                            );
                        })}
                </div>
                <div
                    style={{
                        width: "50%",
                        minHeight: "400px",
                        position: "relative",
                        borderRadius: "16px",
                        overflow: "hidden",
                        border: "1px #DADEF2 solid",
                    }}
                >
                    <CardMapHotel
                        candidates={data?.candidates}
                        latitude={data?.latitude}
                        longitude={data?.longitude}
                        data={data}
                        center={center}
                        setCenter={setCenter}
                        setActiveMarker={setActiveMarker}
                        activeMarker={activeMarker}
                    />
                </div>
            </div>
            <div
                style={{
                    width: "100%",
                    height: 0,
                    border: "1px #F5F6FA solid",
                }}
            ></div>
            <FooterButtonActions
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                loadingMapCondidate={loadingMapCondidate}
                selectedItem={selectedItem}
            />
        </Modal>
    );
};

export default HotelsCandidatesListModal;

const CandidateCard = (props) => {
    const { candidate, index, selectedItem, handleCardClick } = props;
    return (
        <div
            key={index}
            style={{
                width: "100%",
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "24px",
                display: "inline-flex",
                cursor: "pointer",
            }}
            onClick={() => {
                console.log("testtt");
                handleCardClick(candidate);
            }}
            // onClick={handleCardClick(candidate)}
        >
            <div
                style={{
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "16px",
                    display: "inline-flex",
                }}
            >
                <div
                    style={
                        candidate.hotel_code ===
                        selectedItem?.candidate_hotel_code
                            ? SelectedCardStyle
                            : NotSelectedCardStyle
                    }
                >
                    <div
                        style={{
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: "12px",
                            display: "flex",
                        }}
                    >
                        <div
                            style={{
                                width: "30.28px",
                                height: "43px",
                                position: "relative",
                            }}
                        >
                            <MarkerSVG
                                height="43"
                                width="31"
                                index={index ?? 0}
                            />
                        </div>
                        <div
                            style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                gap: "4px",
                                display: "inline-flex",
                            }}
                        >
                            <div>
                                <span
                                    style={{
                                        color: "#141933",
                                        fontSize: "16px",
                                        fontFamily: "Lato",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {candidate?.hotel_code}
                                    &nbsp;{" "}
                                    <span style={{ fontWeight: "700" }}>
                                        {" "}
                                        •{" "}
                                    </span>{" "}
                                    &nbsp;
                                    <span style={{ fontWeight: "600" }}>
                                        {" "}
                                        {candidate?.name}{" "}
                                    </span>
                                </span>
                            </div>
                            <div
                                style={{
                                    color: "#505573",
                                    fontSize: "15px",
                                    fontFamily: "Lato",
                                    fontWeight: "400",
                                    lineHeight: "16px",
                                    wordWrap: "break-word",
                                }}
                            >
                                {candidate?.address}, {candidate?.city},{" "}
                                {candidate?.country}
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            display: "flex",
                            width: "100%",
                            paddingTop: "12px",
                            gap: "18px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                padding: "8px",
                                alignItems: "center",
                                gap: "4px",
                                borderRadius: "8px",
                                border: "1px solid #FBBF24",
                                background: "#FFFBEB",
                                justifyContent: "flex-start",
                            }}
                        >
                            <AlertSVG />
                            {candidate?.unmapped_reason}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <RenderProba proba={candidate?.proba} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CandidateHeader = ({ handleClose }) => {
    return (
        <div
            style={{
                width: "100%",
                height: "72px",
                padding: "24px",
                justifyContent: "space-between",
                alignItems: "center",
                display: "inline-flex",
                borderBottom: "1px #F5F6FA solid",
            }}
        >
            <div
                style={{
                    width: "231px",
                    color: "#141933",
                    fontSize: "18px",
                    fontFamily: "Lato",
                    fontWeight: "700",
                    lineHeight: "24px",
                    wordWrap: "break-word",
                }}
            >
                Match Opportunity
            </div>
            <div
                style={{
                    width: "24px",
                    height: "24px",
                    position: "relative",
                    cursor: "pointer",
                }}
                onClick={handleClose}
            >
                <CloseButtonSVG />
            </div>
        </div>
    );
};

const CandidateHeaderCards = ({ data, selectedItem }) => {
    return (
        <div
            style={{
                width: "100%",
                height: "152px",
                padding: "24px",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "12px",
                display: "inline-flex",
            }}
        >
            <div
                style={{
                    flex: "1 1 0",
                    padding: "16px",
                    background: "#F7F8FF",
                    borderRadius: "16px",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "8px",
                    display: "inline-flex",
                }}
            >
                <div
                    style={{
                        textAlign: "center",
                        color: "#6275E7",
                        fontSize: "13px",
                        fontFamily: "Lato",
                        fontWeight: "700",
                        textTransform: "uppercase",
                        lineHeight: "16px",
                        wordWrap: "break-word",
                    }}
                >
                    Partner hotel
                </div>
                <div
                    style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "12px",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            width: "30.28px",
                            height: "43px",
                            position: "relative",
                        }}
                    >
                        <SVGGoogleMarkerIcon opacity={1} />
                    </div>
                    <div
                        style={{
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: "4px",
                            display: "inline-flex",
                        }}
                    >
                        <div
                            style={{
                                color: "#141933",
                                fontSize: "16px",
                                fontFamily: "Lato",
                                fontWeight: "700",
                                lineHeight: "24px",
                                wordWrap: "break-word",
                            }}
                        >
                            {data?.name}
                        </div>
                        <div
                            style={{
                                color: "#505573",
                                fontSize: "15px",
                                fontFamily: "Lato",
                                fontWeight: "400",
                                lineHeight: "16px",
                                wordWrap: "break-word",
                            }}
                        >
                            {data?.address}, {data?.city}, {data?.country}
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{ width: "24px", height: "24px", position: "relative" }}
            >
                <LinkSvg />
            </div>
            <div
                style={{
                    flex: "1 1 0",
                    padding: "16px",
                    background: "#F7F8FF",
                    borderRadius: "16px",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "8px",
                    display: "inline-flex",
                }}
            >
                <div
                    style={{
                        textAlign: "center",
                        color: "#6275E7",
                        fontSize: "13px",
                        fontFamily: "Lato",
                        fontWeight: "700",
                        textTransform: "uppercase",
                        lineHeight: "16px",
                        wordWrap: "break-word",
                    }}
                >
                    Mapping Opportunity
                </div>
                <div
                    style={{
                        color: "#141933",
                        fontSize: "16px",
                        fontFamily: "Lato",
                        fontWeight: "700",
                        lineHeight: "24px",
                        wordWrap: "break-word",
                    }}
                >
                    {selectedItem?.name}
                </div>
                <div
                    style={{
                        color: "#505573",
                        fontSize: "15px",
                        fontFamily: "Lato",
                        fontWeight: "400",
                        lineHeight: "16px",
                        wordWrap: "break-word",
                    }}
                >
                    {selectedItem?.address}, {selectedItem?.city},{" "}
                    {selectedItem?.country}
                </div>
            </div>
        </div>
    );
};

const FooterButtonActions = ({
    handleClose,
    handleSubmit,
    loadingMapCondidate,
    selectedItem,
}) => {
    return (
        <div
            style={{
                width: "100%",
                height: "98px",
                padding: "24px",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "16px",
                display: "inline-flex",
            }}
        >
            <div
                onClick={handleClose}
                style={{
                    padding: "13px 17px",
                    borderRadius: "8px",
                    border: "1px #878CA8 solid",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "16px",
                    display: "flex",
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        color: "#141933",
                        fontSize: "15px",
                        fontFamily: "Lato",
                        fontWeight: "500",
                        wordWrap: "break-word",
                    }}
                >
                    Cancel
                </div>
            </div>
            <button
                className="btn btn-primary"
                disabled={selectedItem && !loadingMapCondidate ? false : true}
                onClick={handleSubmit}
                style={{
                    // width: "131px",
                    padding: "13px 17px",
                    background: "#6275E7",
                    borderRadius: "8px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "16px",
                    display: "flex",
                    cursor: "pointer",
                }}
            >
                <SVGSubmitIcon />
                <div
                    style={{
                        color: "white",
                        fontSize: "15px",
                        fontFamily: "Lato",
                        fontWeight: "700",
                        wordWrap: "break-word",
                    }}
                >
                    Match
                    {loadingMapCondidate && (
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </div>
            </button>
        </div>
    );
};

const SelectedCardStyle = {
    borderRadius: "16px",
    border: "2px solid #6275E7",
    background: "#F4F5FE",
    display: "flex",
    padding: "12px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
};
const NotSelectedCardStyle = {
    borderRadius: "16px",
    border: "1px #DADEF2 solid",
    background: "white",
    display: "flex",
    padding: "12px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
};
