import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

function Header(props) {
    const { currentTab, setCurrentTab, isAffiliate,reportInfo } = props;
    return (
        <header className="header">
            <div className="container report-container-header">
                <div
                    style={{ justifyContent: "space-between", display: "flex" }}
                >
                    <nav className="navbar-expand-lg navbar-light">
                        <div className="navbar-brand">
                            <Navbar
                                expand="lg"
                                style={{
                                    padding: "0px",
                                }}
                            >
                                <Container>
                                    <Navbar.Brand>
                                        <span className="logo-text">
                                            Cup
                                            <span className="purple-text">
                                                id
                                            </span>
                                            &nbsp;
                                        </span>
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Nav
                                        defaultActiveKey={currentTab}
                                        onSelect={(selectedKey) => {
                                            setCurrentTab(selectedKey);
                                        }}
                                    >
                                        <Nav.Item>
                                            <Nav.Link eventKey="overview">
                                                Overview
                                            </Nav.Link>
                                        </Nav.Item>
                                        {
                                        reportInfo.manual_mapping_token ?
                                        <Nav.Item>
                                            <Nav.Link href={window.location.origin+"/external-manual-mapping?token="+reportInfo.manual_mapping_token}>
                                                Manual mapping 
                                            </Nav.Link>
                                        </Nav.Item>:null
                                        }
                                        {/* {!isAffiliate && (
                                            <Nav.Item>
                                                <Nav.Link eventKey="activity">
                                                    Activity
                                                </Nav.Link>
                                            </Nav.Item>
                                        )}
                                        <Nav.Item>
                                            <Nav.Link eventKey="comparison">
                                                Comparison
                                            </Nav.Link>
                                        </Nav.Item> */}
                                    </Nav>
                                </Container>
                            </Navbar>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}
export default Header;
