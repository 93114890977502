import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const DuplicateService = {
    runDuplicate: async () => {
        const result = await axios
            .get(url + "/hotel-catalogs/run-deduplication", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                if(res.data){
                    return {
                        data: res.data.message,
                        status: res.data.code,
                    }
                }
            })
            .catch((error) => {
                return {
                   message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    refreshDuplicate: async () => {
            const result = await axios
            .get(url + "/hotel-catalogs/deduplication-progress", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                if(res.data){
                    return {
                        data: res.data.data,
                        status: res.data.code,
                    }
                }
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    getduplicationGroup: async (id,keyword,CountrySelected) => {
        let countrySearch = ""
        CountrySelected && Array.isArray(CountrySelected) && CountrySelected.map((item,index)=>{
            if(index == 0)
                countrySearch += `${keyword ? "&" : "?"}country=${item.value}`
            else 
                countrySearch += `,${item.value}`
        })
            const result = await axios
            .get(`${url}/hotel-catalogs/duplication/${id}${keyword ? "?keyword="+keyword : ""}${CountrySelected.length >0 ? countrySearch : countrySearch}`, {
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
            })
            .then((res) => {
                if(res.data){
                    return {
                        data: res.data.data,
                        status: res.data.code,
                    };
                }
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    markDuplicateActive : async (id,keyword) => {
            const result = await axios
            .put(`${url}/hotel-catalogs/duplication/mark-active/${id}/${keyword}`,{}, {
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
            })
            .then((res) => {
                if(res.data){
                    return {
                        data: res.data.message,
                        status: res.data.code,
                    }
                }
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    makeDuplicateActiveDelete : async (id,groupID,HotelCode,data) => {
        const result = await axios
            .put(`${url}/hotel-catalogs/duplication/mark-active-delete/${id}/${groupID}/${HotelCode}`,data, {
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
            })
            .then((res) => {
                if(res.data){
                    return {
                        data: res.data.message,
                        status: res.data.code,
                    }
                }
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    status: error.response.data.code,
                };
            });
        return result
    },
    downloadDuplicate: async (id) => {
        const result = await axios
            .get(url + `/hotel-catalogs/duplication/csv/${id}`, {
                headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
                responseType: "blob",
            })
            .then((res) => {
                if(res.data){
                    return {
                        data: res.data,
                        fileName:"duplicateHotels.csv",
                        status: 200,
                    }
                }
            })
            .catch((error) => {
                return {
                    message: error.response.status == 401 ? "Session expired":"Error while creating file ",
                    status: error.response.status,
                };
            });
        return result;
    },
};
export default DuplicateService;
