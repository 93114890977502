import axios from "axios";
const url = process.env.REACT_APP_BASE_URL;

const ExternalShare = {
    getShareExternal: async (partnerId) => {
        const result = await axios
            .get(url + `/manual-mapping/check-generate-token/${partnerId}`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                return {
                    data: res.data.data,
                    status: 200,
                };
            })
            .catch((error) => {                
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
    PostShareExternal: async (data) => {
        const result = await axios
            .post(url + `/manual-mapping/update-access`, data,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                console.log("res => " , res)
                return {
                    data: res.data?.data,
                    status: 200,
                };
            })
            .catch((error) => {
                console.log("Error", error);
                return {
                    message: error.response.data.msg,
                    status: error.response.data.code,
                };
            });
        return result;
    },
};

export default ExternalShare;
